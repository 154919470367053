import React from 'react'
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";

function Page403() {
    return (
        <div>
            {/* <header>
                <Navbar
                    collapseOnSelect
                    bg="light"
                    expand="lg"
                    className="fixed-top shadow-sm"
                >
                    <Navbar.Brand href="#">
                    <Image fluid src="https://www.mapon.com/images/logo.svg" />
                    </Navbar.Brand>
                </Navbar>
            </header> */}
            <main className="main">
            <Container fluid className="p-0">
                    <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100vh',
                            justifyContent: 'center',
                            position: 'relative',
                            marginTop: '-66px'
                    }}>
                        <h1>403 Unauthorized Access</h1>
                    </div>
            </Container>
            </main>
      </div>
    )
}

export default Page403
