import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Page404 from "./pages/404";
import Page403 from "./pages/403";
import Home from "./pages/Home";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="404">
          <Page404 />
        </Route>
        <Route exact path="403">
          <Page403 />
        </Route>
        <Route path="*">
          <Page403 />
        </Route>
      </Switch>
    </Router>
  );
}
