import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import ScannedData from "./ScannedData";


function Home() {
  let query = useQuery();
  let history = useHistory();
  const [user_token] = useState(query.get("user_token"));
  const [isLoading, setIsLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});

  // on component mount check api key is valid then get company info from mapon and
  useEffect(() => {
    if (user_token !== null) {
      const userInfo = {
        "data": {
            "users": [
                {
                    "id": 1,
                    "company_id": 5087,
                    "user_api_key": "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
                    "email": "info@example.com",
                    "name": "Janis",
                    "surname": "Berzins",
                    "timezone": "Europe\/Riga",
                    "language": "lv",
                    "phone": null,
                    "type": "admin",
                    "distributor_id": 1,
                    "distributor_name": "Distributor name",
                    "distributor_url": "https:\/\/distributor_url.com",
                    "distributor_logo": "https:\/\/distributor_url.com\/path_to_logo"
                }
            ]
        }
    };
    setCompanyInfo(userInfo.data.users[0]);
      // setIsLoading(true);
      // const getCompanyInfo = async (user_token) => {
      //   await API_INSTANCE.post(API_REQUESTS.VERIFY_USER, { 'user_token': user_token })
      //     .then((resp) => resp.data)
      //     .then((companyObj) => {
      //       console.log(companyObj)
      //       if (companyObj.error && companyObj.error.code === 3) {
      //         history.replace("/403");
      //       } else {
      //         setCompanyInfo(companyObj.data.users[0]);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       setIsLoading(false);
      //     });
      // };
      // getCompanyInfo(user_token);
    }
  }, []);

  useEffect(() => {
    console.log(companyInfo);
    if (companyInfo?.id > 0) {
      setIsLoading(false);
    }
  }, [companyInfo]);

  if (user_token === null) {
    return <Redirect to="/403" />;
  }

  if (!isLoading && companyInfo?.id > 0) {
    return <ScannedData companyInfo={companyInfo} />;
  }

  return <div>Please Wait......</div>;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default Home;
