const API_KEY = "14290581d8614950d09d558d1993a602d34f034c";

const API_REQUESTS = {
    COMPANY_INFO: `https://www.mapon.com/api/v1/company/get.json`,
    COMPANY_SCANNED_DATA: `https://mapondriver.demo3.appelit.com/api/scan/product/list`,
    VERIFY_USER: `https://mapondriver.demo3.appelit.com/api/mapon/validate/user`
    // COMPANY_SCANNED_DATA: `http://127.0.0.1:8000/api/scan/product/list`,
    // VERIFY_USER: `http://127.0.0.1:8000/api/mapon/validate/user`    
};

export default API_REQUESTS;