import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

export default function Filters(props) {
  const { barcode, vehicle, driver_id, driver_name, type, damaged } =
    props.objFilters;
  const [value, setOnChange] = useState([new Date(), new Date()]);

  const onInputChange = (event) => {
    props.fieldHander(event);
  };
  const handleClick = (event) => {
    props.applyFilters(event);
  };
  const resetHandle = (event) => {
    props.resetFilters(event);
  };

  const handleDateRange = (value) => {
      console.log(value);
      setOnChange(value);
      props.onDateHandler(value);
  }

  return (
    <div>
      <Form inline>
        <Row>
          <Col sm>
            <InputGroup className="mb-2 mr-sm-2">
              <FormControl
                id="inlineFormInputGroupVehicel"
                name="barcode"
                value={barcode}
                onChange={(e) => onInputChange(e)}
                placeholder="Barcode"
              />
            </InputGroup>
          </Col>
          <Col sm>
            <InputGroup className="mb-2 mr-sm-2">
              <FormControl
                id="inlineFormInputGroupVehicel"
                name="vehicle"
                value={vehicle}
                onChange={(e) => onInputChange(e)}
                placeholder="Vehicle #"
              />
            </InputGroup>
          </Col>
          <Col sm>
            <InputGroup className="mb-2 mr-sm-2">
              <FormControl
                id="inlineFormInputGroupDriverId"
                name="driver_id"
                value={driver_id}
                onChange={(e) => onInputChange(e)}
                placeholder="Driver Id"
              />
            </InputGroup>
          </Col>
          <Col sm>
            <InputGroup className="mb-2 mr-sm-2">
              <FormControl
                id="inlineFormInputGroupDriverName"
                name="driver_name"
                value={driver_name}
                onChange={(e) => onInputChange(e)}
                placeholder="Driver Name"
              />
            </InputGroup>
          </Col>
          <Col sm>
            <InputGroup className="mb-2 mr-sm-2">
              <Form.Control
                name="type"
                value={type}
                onChange={(e) => onInputChange(e)}
                as="select"
              >
                <option defaultValue={type === "all"} value="all">
                  Select Type
                </option>
                <option defaultValue={type === "Load"} value="Load">
                  Load
                </option>
                <option defaultValue={type === "UnLoad"} value="UnLoad">
                  UnLoad
                </option>
              </Form.Control>
            </InputGroup>
          </Col>
          <Col sm>
            <InputGroup className="mb-2 mr-sm-2">
              <Form.Control
                name="damaged"
                value={damaged}
                onChange={(e) => onInputChange(e)}
                as="select"
              >
                <option defaultValue={damaged === "all"} value="all">
                  Select Damaged
                </option>
                <option defaultValue={damaged === "Load"} value="Yes">
                  Yes
                </option>
                <option defaultValue={damaged === "UnLoad"} value="No">
                  No
                </option>
              </Form.Control>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col sm>
            <DateRangePicker
              onChange={handleDateRange}
              value={value}
              className="mb-2 mr-sm-2"
              format="dd-MM-y"
              rangeDivider="to"
              maxDate={new Date()}
              on
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              type="button"
              variant="primary"
              className="mb-2"
              onClick={(e) => handleClick(e)}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
