import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function ScannedItem(props) {
    let type;
    let remarks;
    let createdAt;

    if(props.damageProduct && 
      props.damageProduct.remarks 
      ){
      remarks = props.damageProduct.remarks;
      if(remarks.length >= 30){
        remarks = remarks.substring(0, 30) + '...';
      }
    }

    if(props.type === 'un_load')
        type = <span className="badge badge-warning">UnLoad</span>;
    else
        type = <span className="badge badge-primary">Load</span>;
    // console.log(props.damageProduct);
    
    if(props.createdAt){
      createdAt = new Date(props.createdAt).toLocaleString();
    }

  return (
    <tr>
      <td>{props.id}</td>
      <td>{props.barCode}</td>
      <td>{props.vehicleNumber}</td>
      <td>{props.driverId}</td>
      <td>{props.driverName}</td>
      <td>{type}</td>
      <td style={{textAlign: 'center'}}>{props.damageProduct ? <span className="badge badge-danger">Yes</span> : <span className="badge badge-dark">No</span>}</td>      
      <td>
        {remarks}
      </td>  
      <td style={{whiteSpace: 'nowrap'}}>
        {createdAt}
      </td>
      <td>
        <button className="btn btn-link" onClick={() => props.onItemClick(props.id)} >
          <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
        </button>
      </td>
    </tr>
  );
}

export default ScannedItem;
